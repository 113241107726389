const firebaseConfig = {
  projectId: "the-one-new",
  appId: "1:932269645731:web:1f5ab4f1ef793a4ad54416",
  databaseURL:
    "https://the-one-new-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-one-new.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyBMF3AO0RT-bu0tlIsqtmOD6YLKSWEI8FQ",
  authDomain: "the-one-new.firebaseapp.com",
  messagingSenderId: "932269645731",
  measurementId: "G-37YLC6E8WZ",
};

export default firebaseConfig;
